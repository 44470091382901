//imports
import moment from "moment";
import LessonService from "../../services/lesson.service";
import AvailableService from "../../services/available.service";
import "./LessonModal.scss";
import LoadingElement from "../LoadingElement/LoadingElement";
import { ChangeEvent, useContext, useState } from "react";
import StudentContext from "../../Contexts/StudentContext";

type Prop = {
	title: string;
	start: string;
	end: string;
	id: number;
	notes: string;
	meetLink: string;
	studentName: string | undefined;
	instrumentList: string;
	instructorName: string;
	lessonPrice: number;
	reservedFor?: string;
	addressLineOne: string | null;
	addressLineTwo: string | null;
	addressZipCode: string | null;
	addressCity: string | null;
	addressState: string | null;
	addressDescription: string | null;
	getCalendarEvents?: Function;
	setShowEventInfoModal?: Function;
	setShowEditAvailableModal?: Function;
	setCalendarEvents?: Function;
};

export default function LessonModal(props: Prop) {
	const user = useContext(StudentContext);
	const [loading, setLoading] = useState(false);
	const [notes, setNotes] = useState(props.notes ? props.notes : "");
	function deleteLesson(): void {
		if (window.confirm("Are you sure you would like to cancel this lesson?")) {
			LessonService.deleteLesson(props.id);
			if (props.getCalendarEvents && props.setShowEventInfoModal) {
				props.getCalendarEvents();
				props.setShowEventInfoModal(false);
			}
		}
	}

	function deleteAvailable(): void {
		// TODO: Make sure that available lessons call this function and bought lessons call deleteLesson()
		if (
			window.confirm(
				"Are you sure you would like to delete this available lesson?"
			)
		) {
			AvailableService.deleteAvailable(props.id);
			if (props.getCalendarEvents && props.setShowEventInfoModal) {
				props.getCalendarEvents();
				props.setShowEventInfoModal(false);
			}
		}
	}

	function closeInfoOpenEdit() {
		if (props.setShowEventInfoModal && props.setShowEditAvailableModal) {
			props.setShowEventInfoModal(false);
			props.setShowEditAvailableModal(true);
		}
	}

	function onNoteChange(e: ChangeEvent<HTMLTextAreaElement>) {
		setNotes(e.target.value);
	}

	async function addNotes() {
		setLoading(true);
		var status = await LessonService.addNotesToLesson(props.id, notes);
		if (status === 201) {
			alert("Notes successfully added.");
		} else alert("Something went wrong. Try again later.");
		window.location.reload();
	}

	let instrumentListArray = props.instrumentList
		? props.instrumentList.split(",")
		: ["Error Getting Instruments"];

	return (
		<>
			{loading === true && <LoadingElement size={2} />}
			{loading === false && (
				<div id="lessonDetails">
					<hr />
					<p id="lessonPrice">${props.lessonPrice}</p>
					{props.studentName && (
						<p className="detailHeader">{props.studentName}</p>
					)}
					{props.reservedFor && !props.studentName && (
						<p className="detailHeader">Reserved For {props.reservedFor}</p>
					)}
					{!props.reservedFor && !props.studentName && (
						<p className="detailHeader">Available To Any Students</p>
					)}
					<div>
						<p>{moment(props.start).format("dddd, MMMM Do YYYY")} </p>
						<p>
							{moment(props.start).format("LT")} -{" "}
							{moment(props.end).format("LT")}
						</p>
					</div>

					<p className="detailHeader">Location</p>

					{props.addressLineOne ? (
						<>
							<p>{props.addressLineOne}</p>
							<p>{props.addressLineTwo}</p>
							<p>
								{props.addressCity}, {props.addressState},{" "}
								{props.addressZipCode}
							</p>
							<p>{props.addressDescription}</p>
						</>
					) : (
						<p>Virtual Lesson</p>
					)}

					{props.instrumentList && (
						<>
							<p className="detailHeader">Instruments</p>
							<ul>
								{instrumentListArray.map((val, index) => {
									return <li key={index}>{val}</li>;
								})}
							</ul>
						</>
					)}

					{moment(props.start).diff(moment()) > 0 && (
						<>
							{!!user?.isInstructor === false && (
								<>
									<p className="detailHeader">Notes</p>
									<p>
										{props.notes && <>{props.notes}</>}

										{!props.notes && <>No feedback given yet.</>}
									</p>

									<p
										className={
											moment(props.start).diff(moment(), "minutes") > 10
												? "meetingLink deactivated"
												: "meetingLink activated cursorPoint"
										}
									>
										<a href={props.meetLink}>Join Meeting</a>
									</p>
									<button
										className="deleteLessons cursorPoint"
										onClick={deleteLesson}
									>
										Cancel Lesson
									</button>
								</>
							)}
							{!!user?.isInstructor === true && (
								<>
									{props.meetLink === null && (
										<>
											<button id="editAvailable" onClick={closeInfoOpenEdit}>
												Edit Available Lesson
											</button>
											<button
												className="deleteLessons cursorPoint"
												onClick={deleteAvailable}
											>
												Cancel Available Lesson
											</button>
										</>
									)}
									{props.meetLink !== null && (
										<>
											<p id="meetingLink">
												<a href={props.meetLink}>Join Meeting</a>
											</p>
											<button
												className="deleteLessons cursorPoint"
												onClick={deleteLesson}
											>
												Cancel Lesson
											</button>
										</>
									)}
								</>
							)}
						</>
					)}
					{moment(props.start).diff(moment()) <= 0 && (
						<>
							{!!user?.isInstructor === false && (
								<>
									<p className="detailHeader">Notes</p>
									<p>
										{props.notes && <>{props.notes}</>}

										{!props.notes && <>No feedback given yet.</>}
									</p>
								</>
							)}
							{!!user?.isInstructor === true && (
								<>
									<p className="detailHeader">Notes</p>

									<textarea
										name="notes"
										id="notesInput"
										cols={100}
										rows={10}
										placeholder="Enter lesson notes for your student to review"
										value={notes}
										onChange={onNoteChange}
									></textarea>
									<button className="addNotes" onClick={addNotes}>
										Add notes
									</button>
								</>
							)}
						</>
					)}
				</div>
			)}
		</>
	);
}
