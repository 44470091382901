import { useContext, useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader/ModalHeader";
import LessonModal from "../LessonModal/LessonModal";
import dayGridPlugin from "@fullcalendar/daygrid";
import "./Calendar.scss";

import CalendarService from "../../services/calendar.service";
import { useCookies } from "react-cookie";
import CreateAvailable from "../Create-Available/CreateAvailable";
import config from "../../utils/config";
import StudentContext from "../../Contexts/StudentContext";
import LoadingElement from "../LoadingElement/LoadingElement";

export default function Calendar() {
	const [cookies] = useCookies();
	const [loading, setLoading] = useState(false);
	const [currentEvent, setCurrentEvent] = useState(Object);
	const [showEventInfoModal, setShowEventInfoModal] = useState(false);
	const [showCreateAvailableModal, setShowCreateAvailableModal] =
		useState(false);
	const [showEditAvailableModal, setShowEditAvailableModal] = useState(false);
	const [calendarEvents, setCalendarEvents] = useState(Object);
	const user = useContext(StudentContext);

	useEffect(() => {
		if (!showEventInfoModal) {
			document.title = config.CALENDAR_DOC_TITLE;
			getCalendarEvents();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [showEventInfoModal]);

	async function getCalendarEvents() {
		setLoading(true);

		const events = await CalendarService.getCalendarEvents(cookies.user.id);

		setCalendarEvents(events);
		setLoading(false);
	}

	function switchShowEventInfoModal() {
		setShowEventInfoModal(!showEventInfoModal);
	}

	function switchShowCreateAvailableModal() {
		setShowCreateAvailableModal(!showCreateAvailableModal);
	}

	function switchShowEditAvailableModal() {
		setShowEditAvailableModal(!showEditAvailableModal);
	}

	function copyInstructorProfileToClipboard() {
		navigator.clipboard.writeText(
			`${config.REACT_URL}/instructors/${user?.id}`
		);
		window.alert(
			`Copied the text: ${config.REACT_URL}/instructors/${user?.id}`
		);
	}

	return (
		<>
			{showEventInfoModal && (
				<Modal exitCallback={switchShowEventInfoModal}>
					{currentEvent.title === "Available" ? (
						<ModalHeader headerText={"Available Lesson"} />
					) : (
						<ModalHeader headerText={"Booked Lesson"} />
					)}
					<LessonModal
						title={currentEvent.title}
						start={currentEvent.start.toString()}
						end={currentEvent.end.toString()}
						id={currentEvent.id}
						notes={currentEvent.extendedProps.notes}
						meetLink={currentEvent.extendedProps.meetLink}
						studentName={currentEvent.extendedProps.studentName}
						instrumentList={currentEvent.extendedProps.instrumentList}
						instructorName={currentEvent.extendedProps.instructorName}
						lessonPrice={currentEvent.extendedProps.lessonPrice}
						reservedFor={
							currentEvent.extendedProps.reservedForFirstName
								? `${currentEvent.extendedProps.reservedForFirstName} ${currentEvent.extendedProps.reservedForLastName}`
								: undefined
						}
						addressLineOne={currentEvent.extendedProps.addressLineOne}
						addressLineTwo={currentEvent.extendedProps.addressLineTwo}
						addressZipCode={currentEvent.extendedProps.addressZipCode}
						addressCity={currentEvent.extendedProps.addressCity}
						addressState={currentEvent.extendedProps.addressState}
						addressDescription={currentEvent.extendedProps.addressDescription}
						getCalendarEvents={getCalendarEvents}
						setShowEventInfoModal={setShowEventInfoModal}
						setShowEditAvailableModal={setShowEditAvailableModal}
						setCalendarEvents={setCalendarEvents}
					/>
				</Modal>
			)}

			{showCreateAvailableModal && (
				<CreateAvailable exitCallback={switchShowCreateAvailableModal} />
			)}

			{showEditAvailableModal && (
				<CreateAvailable
					exitCallback={switchShowEditAvailableModal}
					available={{
						id: currentEvent.id,
						startTime: currentEvent.start,
						endTime: currentEvent.end,
						reservedForId: currentEvent.extendedProps.reservedForId,
						reservedForFirstName:
							currentEvent.extendedProps.reservedForFirstName,
						reservedForLastName: currentEvent.extendedProps.reservedForLastName,
						reservedForEmail: currentEvent.extendedProps.reservedForEmail,
						instructorId: currentEvent.extendedProps.instructorId,
						instrumentList: currentEvent.extendedProps.instrumentList,
						price: currentEvent.extendedProps.lessonPrice,
						addressId: currentEvent.extendedProps.addressId,
					}}
				/>
			)}

			<div className="gridContainer">
				<div className="fullScreen">
					{loading && <LoadingElement size={6} />}
					{!loading && (
						<>
							<FullCalendar
								plugins={[dayGridPlugin]}
								initialView="dayGridMonth"
								headerToolbar={{
									start: user?.isInstructor
										? "prev,next shareAvailability"
										: "prev,next",
									center: "title",
									end: "findLessons dayGridMonth,dayGridWeek,dayGridDay",
								}}
								eventClick={function (arg) {
									setCurrentEvent(arg.event);
									setShowEventInfoModal(true);
								}}
								buttonText={{
									month: "Month",
									week: "Week",
									day: "Day",
								}}
								customButtons={
									user?.isInstructor
										? {
												findLessons: {
													text: "Create Lessons",
													click: function () {
														setShowCreateAvailableModal(true);
													},
												},
												shareAvailability: {
													text: "Share Availability",
													click: function () {
														copyInstructorProfileToClipboard();
													},
												},
										  }
										: {
												findLessons: {
													text: "Find Lessons",
													click: function () {
														window.location.href = "/instructors";
													},
												},
										  }
								}
								events={calendarEvents}
							/>
						</>
					)}
				</div>
			</div>
		</>
	);
}
