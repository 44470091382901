import { useEffect, useState } from "react";
import "./InstructorProfile.scss";
import InstructorProfileHeader from "./InstructorProfileHeader";
import UpcomingLessonsList from "./UpcomingLessonsList/UpcomingLessonsList";
import InstructorService from "../../services/instructor.service";
import { useParams } from "react-router-dom";
import CartService from "../../services/cart.service";
import config from "../../utils/config";
import LoadingElement from "../LoadingElement/LoadingElement";

function InstructorProfile() {
	let shownInstruments: JSX.Element[] = [];
	let hiddenInstruments: JSX.Element[] = [];
	const { instructorId } = useParams();
	const [instructor, setInstructor] = useState(Object);
	const [showFullBio, setShowFullBio] = useState<boolean>(false);
	const [showFullInstrumentList, setShowFullInstrumentList] =
		useState<boolean>(false);
	const [instrumentList, setinstrumentList] = useState<string[]>([]);
	const [cartLength, setCartlength] = useState<number>(0);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		document.title = `Instructor ${config.DOC_TITLE_SUFFIX}`;

		try {
			loadInstructorAndCart();
		} catch (err) {
			console.log(err);
			window.document.location = "/error";
		}

		async function loadInstructorAndCart() {
			setLoading(true);

			const instructor = await InstructorService.getInstructor(
				parseInt(instructorId!)
			);
			setInstructor(instructor);
			document.title = `${instructor.firstName + " " + instructor.lastName} ${
				config.DOC_TITLE_SUFFIX
			}`;
			setinstrumentList(instructor.instrumentList!.split(","));

			const carts = await CartService.getCarts();

			setCartlength(carts.length);
			setLoading(false);
		}
	}, [instructorId]);

	function showFullBioClickHandler() {
		setShowFullBio(!showFullBio);
	}

	function showFullInstrumentListClickHandler() {
		setShowFullInstrumentList(!showFullInstrumentList);
	}

	Array.from(instrumentList).forEach((instrument: string, index: number) => {
		if (index < 5) shownInstruments.push(<li key={index}>{instrument}</li>);
		else hiddenInstruments.push(<li key={index}>{instrument}</li>);
	});

	return (
		<div className="content gridContainer">
			<div className="fullScreen" id="instructorProfile">
				{loading && <LoadingElement size={6} />}
				{!loading && (
					<>
						<InstructorProfileHeader
							cartLength={cartLength}
							instructor={instructor}
						/>

						<hr id="betweenInstructorHeaderBody" />

						<div id="instructorProfileBody">
							<div id="instructorAboutInstruments">
								<div id="instructorAbout">
									<h3>About</h3>
									{instructor.bio?.length > 500 && (
										<>
											<p>
												{instructor.bio?.substring(0, 500)}
												{showFullBio === true && (
													<span>{instructor.bio?.substring(500)}</span>
												)}
											</p>
											<br />
											{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
											<a
												className="underlineLink"
												onClick={showFullBioClickHandler}
											>
												View More
											</a>
										</>
									)}
									{instructor.bio?.length <= 500 && <p>{instructor.bio}</p>}
								</div>
								<div id="instructorInstruments">
									<h3>Lessons Offered</h3>
									<ul id="lessonList">
										{shownInstruments}
										{showFullInstrumentList && hiddenInstruments}
										<br />
										{hiddenInstruments.length > 0 &&
											!showFullInstrumentList && (
												<>
													{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
													<a
														className="underlineLink"
														onClick={showFullInstrumentListClickHandler}
													>
														View More
													</a>
												</>
											)}
										{hiddenInstruments.length > 0 && showFullInstrumentList && (
											<>
												{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
												<a
													className="underlineLink"
													onClick={showFullInstrumentListClickHandler}
												>
													View Less
												</a>
											</>
										)}
									</ul>
								</div>
							</div>

							<UpcomingLessonsList
								cartLength={cartLength}
								setCartLength={setCartlength}
							/>
						</div>
					</>
				)}
			</div>
		</div>
	);
}

export default InstructorProfile;
