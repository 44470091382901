import moment from "moment";
import { useState } from "react";
import LessonModal from "../../../LessonModal/LessonModal";
import Modal from "../../../Modal/Modal";
import ModalHeader from "../../../Modal/ModalHeader/ModalHeader";
import LessonType from "../../../../Types/LessonType";

type Props = {
	date: string;
	startTime: string;
	name: string;
	meetLink: string;
	lesson: LessonType;
};
export default function DashboardLesson(props: Props) {
	const [showLessonDetailsModal, setShowLessonDetailsModal] = useState(false);

	function modalHandler() {
		setShowLessonDetailsModal(!showLessonDetailsModal);
	}

	return (
		<li id="futureLessonListObject">
			{showLessonDetailsModal && (
				<Modal exitCallback={modalHandler}>
					<ModalHeader headerText={`Lesson with ${props.name}`} />
					<LessonModal
						title={`Lesson with ${props.name}`}
						start={props.lesson.chosenStart}
						end={props.lesson.chosenEnd}
						id={props.lesson.lessonId}
						notes={props.lesson.notes!}
						meetLink={props.lesson.meetLink}
						studentName={props.lesson.studentName!}
						instrumentList={props.lesson.instrumentName!}
						instructorName={props.lesson.instructorName!}
						lessonPrice={parseFloat(props.lesson.price)}
						reservedFor={""}
						addressLineOne={props.lesson.addressLineOne ?? null}
						addressLineTwo={props.lesson.addressLineTwo ?? null}
						addressZipCode={props.lesson.addressZipCode ?? null}
						addressCity={props.lesson.addressCity ?? null}
						addressState={props.lesson.addressState ?? null}
						addressDescription={props.lesson.addressDescription ?? null}
					/>
				</Modal>
			)}

			{moment(props.lesson.chosenStart).diff(moment(), "minutes") > 10 && (
				<div>
					<a href={props.meetLink} className="outDivLinkButton deactivated">
						Join Now
					</a>
				</div>
			)}

			{moment(props.lesson.chosenStart).diff(moment(), "minutes") <= 10 && (
				<div>
					<a
						href={props.meetLink}
						className="outDivLinkButton activated cursorPoint"
					>
						Join Now
					</a>
				</div>
			)}

			<p>
				{props.date} - {props.startTime}
			</p>
			<p>Lesson with {props.name}</p>
			<button onClick={modalHandler} className="bareButton greenText">
				See More
			</button>
			<hr />
		</li>
	);
}
